<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Customers/Create</h4>
          <div>
            <router-link :to="{...previousRoute}">
              <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
            </router-link>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="col-12">
              <div v-if="!userSearchStatus" class="position-relative" style="min-height: 60vh">
                <div class="position-absolute " style="top: 50%;transform:translateY(-50%); left: 0;right: 0">
                  <h5 class="text-center mb-2">To create an Customer on the system, start by first inputting the
                    specified email address or phone number.</h5>
                  <div class="row center-div mx-auto">
                    <div class="col-lg-10 col-sm-12 ">
                      <div class="has-icon-left position-relative">
                        <input type="text" id="userSearch" class="form-control" v-model="userSearchQuery"
                               placeholder="Type full email address or phone number">
                        <div class="form-control-position"><i class='bx bx-search-alt'></i></div>
                      </div>
                      <div v-if="errors.existingRegisteredCustomer" class="text-danger">
                        {{ errors.existingRegisteredCustomer }}
                      </div>

                      <div v-if="registeredEmailDomains.length > 0" class="email-domain-list">
                        <div v-for="(registeredEmailDomain, index) in registeredEmailDomains" :key="index">
                          <p @click="margeUserSearchQuery(registeredEmailDomain)" class="mb-0 email-domain-list-item">
                            {{ registeredEmailDomain }}</p>
                          <p></p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-2 col-sm-12 mt-2 mt-lg-0">
                      <button @click="searchUser" type="button" class="btn btn-block btn-primary">Submit</button>
                    </div>
                  </div>
                  <div v-if="existingRegisteredCustomer.id" class="row center-div mx-auto">

                    <div class="col-12 mt-1">
                      <hr>
                      <h5 class="col-12 d-flex flex-row bx-flashing text-warning">
                        <i class='bx bx-info-circle' style="line-height: 1.5rem !important;"></i>
                        <span>&nbsp;Customer Already Exist!</span>
                      </h5>
                      <p class="col-12 d-md-flex justify-md-content-start align-items-center">
                        <strong>Customer Info:</strong>
                        <span>&nbsp;{{ existingRegisteredCustomerDetails }}&nbsp;&nbsp;</span>
                        <router-link v-if="existingRegisteredCustomer.id"
                                     :to="can('customer-view') ? {name: 'appCustomerView', params: {id: existingRegisteredCustomer.id }} : '#'"
                                     :title="can('customer-view') ? 'View' : 'View forbidden'">
                          <span class="glow d-flex align-items-center"><i class='bx bx-show'></i>&nbsp;View</span>
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="row">
                  <!-- left side numbering steps of the process start. -->
                  <div class="col-lg-2 step-divider">
                    <div class="d-flex align-items-center cursor-pointer">
                      <div :class="tabStep >= 1 ? 'active-step' : 'inactive-step'"
                           class="step-number d-flex justify-content-center align-items-center flex-shrink-0">1
                      </div>
                      <div class="ml-1" :class="tabStep >= 1 ? 'text-primary' : ''">User Details</div>
                    </div>
                    <div class="step-line d-flex justify-content-center ">
                      <div :class="tabStep > 1 ? 'inner-line-primary-border' : 'inner-line-secondary-border'"
                           class="inner-line "></div>
                    </div>
                    <div class="d-flex align-items-center cursor-pointer">
                      <div :class="tabStep >= 2 ? 'active-step' : 'inactive-step'"
                           class="step-number d-flex justify-content-center align-items-center flex-shrink-0">2
                      </div>
                      <div class="ml-1" :class="tabStep >= 2 ? 'text-primary' : ''">
                        {{ postCustomerData.type === 2 ? "Business & Address Details" : "Address Details" }}
                      </div>
                    </div>
                    <div class="step-line d-flex justify-content-center ">
                      <div :class="tabStep > 2 ? 'inner-line-primary-border' : 'inner-line-secondary-border'"
                           class="inner-line "></div>
                    </div>
                    <div class="d-flex align-items-center cursor-pointer">
                      <div :class="tabStep >= 3 ? 'active-step' : 'inactive-step'"
                           class="step-number d-flex justify-content-center align-items-center flex-shrink-0">3
                      </div>
                      <div class="ml-1" :class="tabStep >= 3 ? 'text-primary' : ''">Referrer & Others</div>
                    </div>
                    <div class="step-line d-flex justify-content-center ">
                      <div :class="tabStep > 3 ? 'inner-line-primary-border' : 'inner-line-secondary-border'"
                           class="inner-line "></div>
                    </div>
                    <div class="d-flex align-items-center cursor-pointer">
                      <div :class="tabStep >= 4 ? 'active-step' : 'inactive-step'"
                           class="step-number d-flex justify-content-center align-items-center flex-shrink-0">4
                      </div>
                      <div class="ml-1" :class="tabStep >= 4 ? 'text-primary' : ''">Preview Details</div>
                    </div>
                  </div>
                  <!-- left side numbering steps of the process end. -->
                  <!-- right side response steps of the process starts. -->
                  <div class="col-lg-10 mt-3 mt-lg-0">
                    <div class="row" v-if="tabStep===1">
                      <div class="col-12">
                        <h5>User Information</h5>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>First name *</label>
                          <input v-model="postUserData.first_name" type="text" class="form-control "
                                 placeholder="Enter first name">
                          <div class="text-danger" v-if="errors.user.firstName">{{ errors.user.firstName }}</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Last name *</label>
                          <input v-model="postUserData.last_name" type="text" class="form-control "
                                 placeholder="Enter last name">
                          <div class="text-danger" v-if="errors.user.lastName">{{ errors.user.lastName }}</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Phone Number *</label>
                          <input v-model="postUserData.phone_number" :readonly="userPhoneFilled" max="10" type="tel"
                                 class="form-control " placeholder="ie. 0412345678"
                                 aria-describedby="userAlreadyExistWarning">
                          <small v-if="userFound" id="userAlreadyExistWarning"
                                 class="form-text text-warning d-flex flex-row">
                            <strong><i class='bx bx-info-circle'></i></strong>&nbsp;
                            Previously registered users! Any changes will update this user's data.
                          </small>
                          <div class="text-danger" v-if="errors.user.phoneNumber">{{ errors.user.phoneNumber }}</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Email Address *</label>
                          <input v-model="postUserData.email" :readonly="userEmailFilled" type="email"
                                 class="form-control" placeholder="ie. example@example.com.au">
                          <div class="text-danger" v-if="errors.user.email">{{ errors.user.email }}</div>
                          <div class="text-danger" v-if="errors.address.userId">{{ errors.address.userId }}</div>
                          <div class="text-danger" v-if="errors.business.userId">{{ errors.business.userId }}</div>
                          <div class="text-danger" v-if="errors.customer.userId">{{ errors.customer.userId }}</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Date of Birth</label>
                          <div class="d-flex align-items-center date-picker-wrapper">
                            <div class="d-flex align-items-center">
                              <i class='bx bx-calendar'></i>
                            </div>
                            <div class="w-100 pl-1 ">
                              <div class="w-100 ">
                                <DatePicker v-model="postUserData.date_of_birth"
                                            :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}"
                                            :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                                  <template v-slot="{ inputValue, inputEvents }">
                                    <input :value="inputValue" v-on="inputEvents" class="form-control"/>
                                  </template>
                                </DatePicker>
                              </div>
                            </div>
                          </div>
                          <div class="text-danger" v-if="errors.user.dateOfBirth">{{ errors.user.dateOfBirth }}</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Customer type</label>
                          <VueMultiselect v-model="selectedCustomerType" class="" :options="customerTypeList"
                                          :close-on-select="true" placeholder="Select a customer type" label="name"
                                          track-by="name" :show-labels="false" :allow-empty="false"/>
                          <div class="text-danger" v-if="errors.customer.type">{{ errors.customer.type }}</div>

                        </div>
                      </div>

                      <div class="col-12">
                        <div class="d-flex justify-content-end">
                          <button @click="jumpToStart" class="btn btn-light-primary">Prev</button>
                          <button @click="createSingleUser" :disabled="!isPostUserAndPostCustomerTypeDataFilled"
                                  class="btn btn-light-primary ml-1">Next
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="tabStep===2">
                      <div class="col-12">
                        <h5>
                          {{ postCustomerData.type === 2 ? "Business & Address Information" : "Address Information" }}
                        </h5>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label>
                            {{
                              addressInformationSelectDiv && postCustomerData.type === 2 ? "Business & Address" : "Address"
                            }}
                          </label>
                          <small v-if="!addressInformationSelectDiv && hasUserAddresses"
                                 @click="addressInformationSelectDiv = !addressInformationSelectDiv"
                                 class="text-primary">&nbsp;(Click to select address from existing one)</small>
                          <small v-if="addressInformationSelectDiv"
                                 @click="addressInformationSelectDiv = !addressInformationSelectDiv"
                                 class="text-primary">&nbsp;(Click to create a new address)</small>
                          <VueMultiselect v-if="addressInformationSelectDiv" v-model="selectedAddress"
                                          :options="addressList" :close-on-select="true" placeholder="Select Address"
                                          label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
                          <vue-google-autocomplete v-else :enable-geolocation="true" ref="address" :country="['au']"
                                                   id="map" classname="form-control"
                                                   placeholder="Please type full address."
                                                   v-on:placechanged="googleMapApiResponseData"/>
                          <div class="text-danger" v-if="errors.business.addressId">
                            {{ errors.business.addressId }}
                          </div>
                          <div class="text-danger" v-if="errors.customer.addressId">
                            {{ errors.customer.addressId }}
                          </div>
                        </div>
                      </div>
                      <div v-if="!addressInformationSelectDiv && hasAddressData"
                           class="col-12 d-flex flex-row bx-flashing text-warning">
                        <i class='bx bx-info-circle' style="line-height: 1.5rem !important;"></i>
                        <span>&nbsp;Please modify the following address fields, if there anything wrong!</span>
                      </div>
                      <div v-if="!addressInformationSelectDiv && hasAddressData" class="col-12 col-lg-3 col-md-6">
                        <div class="form-group">
                          <label>Flat/Unit</label>
                          <input v-model="postAddressData.subpremise" type="text" class="form-control"
                                 placeholder="Enter flat/unit number"/>
                          <div class="text-danger" v-if="errors.address.subpremise">{{ errors.address.subpremise }}</div>
                        </div>
                      </div>
                      <div v-if="!addressInformationSelectDiv && hasAddressData" class="col-12 col-lg-3 col-md-6">
                        <div class="form-group">
                          <label>Street Address *</label>
                          <input v-model="postAddressData.street" type="text" class="form-control"
                                 placeholder="Enter street address"/>
                          <div class="text-danger" v-if="errors.address.street">{{ errors.address.street }}</div>
                        </div>
                      </div>
                      <div v-if="!addressInformationSelectDiv && hasAddressData" class="col-12 col-lg-3 col-md-6 ">
                        <div class="form-group">
                          <label>Suburbs/ Territory *</label>
                          <input v-model="postAddressData.suburb" type="text" class="form-control"
                                 placeholder="Enter suburbs/ territory"/>
                          <div class="text-danger" v-if="errors.address.suburb">{{ errors.address.suburb }}</div>
                        </div>
                      </div>
                      <div v-if="!addressInformationSelectDiv && hasAddressData" class="col-12 col-lg-3 col-md-6">
                        <div class="form-group">
                          <label>State *</label>
                          <VueMultiselect v-model="selectedState" :allowEmpty="false" :options="stateList"
                                          :close-on-select="true" placeholder="Select state" label="name"
                                          track-by="value" :show-labels="false"/>
                          <div class="text-danger" v-if="errors.address.state">{{ errors.address.state }}</div>
                        </div>
                      </div>
                      <div v-if="!addressInformationSelectDiv && hasAddressData" class="col-12 col-lg-3 col-md-6">
                        <div class="form-group">
                          <label>Post Code *</label>
                          <input v-model="postAddressData.post_code" type="text" class="form-control"
                                 placeholder="Enter code" required/>
                          <div class="text-danger" v-if="errors.address.postCode">{{ errors.address.postCode }}</div>
                        </div>
                      </div>
                      <div v-if="!addressInformationSelectDiv && hasAddressData" class="col-12 col-lg-3 col-md-6">
                        <div class="form-group">
                          <label>Country *</label>
                          <input v-model="postAddressData.country" type="text" class="form-control" readonly required/>
                          <div class="text-danger" v-if="errors.address.country">{{ errors.address.country }}</div>
                        </div>
                      </div>
                      <div v-if="!addressInformationSelectDiv && postCustomerData.type === 2" class="col-12">
                        <div class="form-group">
                          <label>Business Name *</label>
                          <input v-model="postBusinessData.name" type="text" class="form-control"/>
                          <div class="text-danger" v-if="errors.business.name">{{ errors.business.name }}</div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="d-flex justify-content-end">
                          <button class="btn btn-light-primary" @click="tabPrev">Prev</button>
                          <button v-if="addressInformationSelectDiv" class="btn btn-light-primary ml-1"
                                  :disabled="!selectedAddress.value" @click="tabNext">Next
                          </button>
                          <button v-else class="btn btn-light-primary ml-1"
                                  :disabled="!hasAddressData || (postCustomerData.type === 2 && postBusinessData.name ==='')"
                                  @click="createSingleAddress">Next
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="tabStep ===3">
                      <div class="col-12"><h5>Referrer & Others Information</h5></div>
                      <div class="col-12">
                        <div class="form-group">
                          <div class="">
                            <label>Referral code</label>
                            <input v-model="referralCode" type="text" class="form-control">
                            <div class="text-danger" v-if="errors.referrer.referralCode">
                              {{ errors.referrer.referralCode }}
                            </div>
                            <div class="text-danger" v-if="errors.customer.referredBy">
                              {{ errors.customer.referredBy }}
                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <div class="controls">
                            <label>Newsletter Subscription</label>
                            <div style="margin-top: .5rem">
                              <div
                                  class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                <div>
                                  <input v-model="newsletterSubscription" type="checkbox" class="custom-control-input"
                                         checked id="newsletterSubscriptionStatus">
                                  <label class="custom-control-label mr-1" for="newsletterSubscriptionStatus"></label>
                                </div>
                                <span class="font-medium-1">{{ newsletterSubscription ? "Active" : "Inactive" }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="text-danger" v-if="errors.customer.newsletterSubscription">
                            {{ errors.customer.newsletterSubscription }}
                          </div>

                        </div>
                      </div>

                      <div class="col-12">
                        <div class="d-flex justify-content-end">
                          <button class="btn btn-light-primary" @click="tabPrev">Prev</button>
                          <button class="btn btn-light-primary ml-1" @click="tabNext">Next</button>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="tabStep ===4">
                      <div class="col-12"><h3>Preview Details</h3></div>
                      <div class="col-12">
                        <table class="table table-responsive table-borderless table-transparent">
                          <tbody>
                          <tr>
                            <td class="bold">First Name</td>
                            <td>:&nbsp;{{ postUserData.first_name }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Last Name</td>
                            <td>:&nbsp;{{ postUserData.last_name }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Phone Number</td>
                            <td :class="postUserData.phone_number ? '' : 'text-danger'">:&nbsp;{{ postUserData.phone_number ?? 'Not Provided' }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Email</td>
                            <td :class="postUserData.email ? '' : 'text-danger'">:&nbsp;{{ postUserData.email ??  'Not Provided' }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Date of Birth</td>
                            <td :class="postUserData.date_of_birth ? '' : 'text-danger'">:&nbsp;{{ postUserData.date_of_birth ?? 'Not Provided' }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Customer Type</td>
                            <td>:&nbsp;{{ customerType }}</td>
                          </tr>
                          <tr>
                            <td class="bold">{{ postCustomerData.type === 2 ? "Business & Address" : "Address" }}</td>
                            <td>:&nbsp;{{ addressInformationSelectDiv ? selectedAddress.name : filledAddress }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Referral Code</td>
                            <td :class="referralCode ? '' : 'text-danger'">:&nbsp;{{ referralCode ?? 'Not Submitted' }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Newsletter Subscription Status</td>
                            <td>:&nbsp;{{ postCustomerData.newsletter_subscription ? "Subscribed" : "Unsubscribed" }}
                            </td>
                          </tr>
                          </tbody>
                        </table>
                        <div class="text-danger" v-if="errors.customer.status">{{ errors.customer.status }}</div>
                      </div>
                      <div class="col-12">
                        <div class="d-flex justify-content-end">
                          <button class="btn btn-light-primary" @click="tabPrev">Prev</button>
                          <button class="btn btn-light-primary ml-1" @click="createSingleCustomer">Create Customer
                          </button>
                        </div>
                      </div>

                    </div>
                  </div>
                  <!-- right side response steps of the process end. -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
// component
import AppLayout from '@/layouts/backEnd/AppLayout';

// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

// core package
import {
  mapActions,
  mapGetters
} from "vuex";

// package
import {DatePicker} from 'v-calendar';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import VueMultiselect from 'vue-multiselect';
import GlobalMixin from "@/components/backEnd/mixins/GlobalMixin";

export default {
  name: "CustomerCreate",
  mixins: [ShowToastMessage, Loader, Authorization, GlobalMixin],
  props: ['routeName'],
  components: {
    AppLayout,
    DatePicker,
    VueGoogleAutocomplete,
    VueMultiselect,
  },

  data() {
    return {
      userSearchQuery: '',
      userSearchEmailDomainPartQuery: '',
      userSearchStatus: false,
      userFound: false,
      userEmailFilled: false,
      userPhoneFilled: false,
      tabStep: 1,
      userId: null,
      existingRegisteredCustomer: {
        id: '',
        userId: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
      },

      postUserData: {
        role_id: '',
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        date_of_birth: '',
      },

      existingRegisteredUserData: {
        role_id: '',
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        date_of_birth: '',
      },

      addressInformationSelectDiv: false,
      hasUserAddresses: false,

      selectedCustomerType: {
        'value': '',
        'name': 'Any',
      },

      selectedAddress: {
        value: '',
        name: 'None'
      },

      postAddressData: {
        user_id: '',
        subpremise: '',
        street: '',
        suburb: '',
        state: '',
        post_code: '',
        country: '',
      },

      selectedState: {
        value: '',
        name: 'None'
      },

      postBusinessData: {
        user_id: '',
        address_id: '',
        name: '',
      },

      postCustomerData: {
        referred_by: '',
        address_id: '',
        user_id: '',
        type: '',
        newsletter_subscription: 0,
        status: 1,
      },

      customerTypeList: [
        {
          value: 1,
          name: "Home",
        },
        {
          value: 2,
          name: "Business",
        },
      ],

      referralCode: '',

      newsletterSubscription: false,

      errors: {
        existingRegisteredCustomer: '',
        user: {
          roleId: '',
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          dateOfBirth: '',
        },
        address: {
          userId: '',
          subpremise: '',
          street: '',
          suburb: '',
          state: '',
          postCode: '',
          country: '',
        },
        business: {
          userId: '',
          addressId: '',
          name: '',
        },
        customer: {
          referredBy: '',
          addressId: '',
          userId: '',
          type: '',
          status: '',
          newsletterSubscription: '',
        },
        referrer: {
          referralCode: '',
        },
      },

      getSettingsParams: {
        type: ['default'],
        key: ['default_state', 'default_customer_type', 'default_customer_role'],
      },
      getUsersParams: {
        email_phone_where_has_user_additional_infos_query: '',
        with_relation: ['addresses', 'addresses.business', 'customer'],
      },
    }
  },

  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      users: 'appUsers/users',
      referrerCustomer: 'appCustomers/referrerCustomer',
      registeredEmailDomains: 'appUsers/registeredEmailDomains'
    }),

    redirectRouteName() {
      return this.can('customer-view-any') ? 'appCustomerList' : 'appCustomerCreate';
    },

    registeredEmailDomains() {

      let registeredEmailDomains = this.$store.getters['appUsers/registeredEmailDomains'].email_domains;

      if (this.userSearchEmailDomainPartQuery !== '' && Array.isArray(registeredEmailDomains)) {
        return this.$store.getters['appUsers/registeredEmailDomains'].email_domains.filter((str) => {
          return str.toLowerCase().indexOf(this.userSearchEmailDomainPartQuery.toLowerCase()) >= 0;
        });
      }

      if (this.userSearchEmailDomainPartQuery !== '' && (typeof registeredEmailDomains) === 'object') {
        return Object.values(registeredEmailDomains).filter((str) => {
          return str.toLowerCase().indexOf(this.userSearchEmailDomainPartQuery.toLowerCase()) >= 0;
        });
      }

      return [];
    },

    existingRegisteredCustomerDetails() {
      return `${this.existingRegisteredCustomer.firstName} ${this.existingRegisteredCustomer.lastName} (${this.existingRegisteredCustomer.email} | ${this.existingRegisteredCustomer.phoneNumber})`;
    },

    isPostUserAndPostCustomerTypeDataFilled() {
      return !!(this.postUserData.first_name && this.postUserData.last_name && (this.postUserData.email || this.postUserData.phone_number) && this.postCustomerData.type !== '');
    },

    existingRegisteredUserDataHasChanged() {
      return this.postUserData.first_name !== this.existingRegisteredUserData.first_name ||
          this.postUserData.last_name !== this.existingRegisteredUserData.last_name ||
          this.postUserData.email !== this.existingRegisteredUserData.email ||
          this.postUserData.phone_number !== this.existingRegisteredUserData.phone_number ||
          this.postUserData.date_of_birth !== this.existingRegisteredUserData.date_of_birth;
    },

    addressList() {
      let userAddresses = this.users && this.users[0] && this.users[0].addresses ? this.postCustomerData.type === 2 ?
          this.users[0].addresses.filter((address) => address.business && address.business.id).map((address) => {
            let businessName = address.business && address.business.name ? address.business.name : '';
            let addressId = address.id;
            let addressStreet = address.street;
            let addressSuburb = address.suburb;
            let addressState = address.state.toUpperCase();
            let addressPostCode = address.post_code;
            let addressCountry = address.country;

            return {
              value: addressId,
              name: `${businessName} (${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry})`,
            };
          }) :
          this.users[0].addresses.map((address) => {
            let addressId = address.id;
            let addressStreet = address.street;
            let addressSuburb = address.suburb;
            let addressState = address.state.toUpperCase();
            let addressPostCode = address.post_code;
            let addressCountry = address.country;

            return {
              value: addressId,
              name: `${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`
            };
          }) : [];

      return [
        {
          value: '',
          name: 'None'
        },
        ...userAddresses
      ];
    },

    stateList() {
      return this.$store.getters['appSettings/settingDefaultState'].value
    },

    hasAddressData() {
      return !!(this.postAddressData.street || this.postAddressData.suburb || this.postAddressData.state || this.postAddressData.post_code);
    },

    isPostAddressDataFilled() {
      return !!(this.postAddressData.street && this.postAddressData.suburb && this.postAddressData.state && this.postAddressData.post_code && this.postAddressData.country);
    },

    filledAddress() {
      let businessName = this.postBusinessData.name ?? '';
      let addressSubpremise = this.postAddressData.subpremise;
      let addressStreet = this.postAddressData.street;
      let addressSuburb = this.postAddressData.suburb;
      let addressState = this.postAddressData.state.toUpperCase();
      let addressPostCode = this.postAddressData.post_code;
      let addressCountry = this.postAddressData.country;

      return this.postCustomerData.type === 2 ?
          `${businessName} (${addressSubpremise} ${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry})` :
          `${addressSubpremise} ${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`;
    },

    customerType() {
      if (this.postCustomerData.type === 0) {
        return 'Guest';
      } else if (this.postCustomerData.type === 1) {
        return "Home";
      } else if (this.postCustomerData.type === 2) {
        return "Business";
      } else {
        return "";
      }
    }

  },

  watch: {
    userSearchQuery(userSearchQuery) {
      this.userSearchEmailDomainPartQuery = userSearchQuery.includes('@') ? userSearchQuery.substring(userSearchQuery.lastIndexOf('@') + 1) : '';
    },

    selectedCustomerType(selectedCustomerType) {
      this.postCustomerData.type = selectedCustomerType.value;
    },

    selectedAddress(selectedAddress) {
      this.postCustomerData.address_id = selectedAddress.value ?? null;
    },

    selectedState(selectedState) {
      this.postAddressData.state = selectedState.value;
    },

    newsletterSubscription(newsletterSubscription) {
      this.postCustomerData.newsletter_subscription = newsletterSubscription ? 1 : 0;
    },

  },

  methods: {
    ...mapActions({
      resetUser: 'appUsers/resetUser',
      resetUsers: 'appUsers/resetUsers',
      resetAddress: 'appAddresses/resetAddress',
      resetBusiness: 'appBusinesses/resetBusiness',
      resetCustomer: 'appCustomers/resetCustomer',

      postUser: 'appUsers/postUser',
      putUser: 'appUsers/putUser',
      postAddress: 'appAddresses/postAddress',
      postBusiness: 'appBusinesses/postBusiness',
      getReferrer: 'appCustomers/getReferrer',
      postCustomer: 'appCustomers/postCustomer',

      getSettings: 'appSettings/getSettings',
      getUsers: 'appUsers/getUsers',
      getRegisteredEmailDomains: 'appUsers/getRegisteredEmailDomains',


      // loyalty
      postLoyaltyCustomerRegistration: 'apployaltyPoints/postLoyaltyCustomerRegistration',
      postLoyaltyCustomerRefferar: 'apployaltyPoints/postLoyaltyCustomerRefferar'
    }),

    async margeUserSearchQuery(registeredEmailDomain) {
      let firstPart = this.userSearchQuery.substring(0, this.userSearchQuery.lastIndexOf('@'));
      this.userSearchQuery = `${firstPart}@${registeredEmailDomain}`;
      await this.searchUser();
      this.userSearchEmailDomainPartQuery = '';
    },

    async tabPrev() {
      this.tabStep--;
    },

    async tabNext() {
      this.tabStep++;
    },

    async tabJump(tabToJump) {
      this.tabStep = tabToJump;
    },

    async setExistingRegisteredCustomer(user, customerId) {
      this.existingRegisteredCustomer = {
        id: customerId,
        userId: user.id,
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        phoneNumber: user.phone_number,
      };
    },

    async resetExistingRegisteredCustomer() {
      this.existingRegisteredCustomer = {
        id: '',
        userId: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
      };
    },

    async setExistingRegisteredCustomerError() {
      this.errors.existingRegisteredCustomer = 'The user is already registered as a customer.';
    },
    async resetExistingRegisteredCustomerError() {
      this.errors.existingRegisteredCustomer = '';
    },


    async setExistingRegisteredUserData(user) {
      this.existingRegisteredUserData.role_id = user.role && user.role.id ? user.role.id : this.$store.getters["appSettings/settingDefaultCustomerRole"].value?.value;
      this.existingRegisteredUserData.first_name = user.first_name ?? '';
      this.existingRegisteredUserData.last_name = user.last_name ?? '';
      this.existingRegisteredUserData.email = user.email ?? '';
      this.existingRegisteredUserData.phone_number = user.phone_number ?? '';
      this.existingRegisteredUserData.date_of_birth = user.date_of_birth ?? '';
    },

    async setPostUserData(user) {
      this.postUserData.role_id = user.role && user.role.id ? user.role.id : this.$store.getters["appSettings/settingDefaultCustomerRole"].value?.value;
      this.postUserData.first_name = user.first_name ?? '';
      this.postUserData.last_name = user.last_name ?? '';
      this.postUserData.email = user.email ?? '';
      this.postUserData.phone_number = user.phone_number ?? '';
      this.postUserData.date_of_birth = user.date_of_birth ?? '';
    },
    async resetPostUserData() {
      this.postUserData = {
        role_id: '',
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        date_of_birth: '',
      };
    },

    async resetSelectedRole() {
      this.selectedRole = {
        value: '',
        name: 'None'
      };
    },

    async resetSelectedAddress() {
      this.selectedAddress = {
        value: '',
        name: 'None'
      };
    },

    async resetErrors() {
      this.errors = {
        existingRegisteredCustomer: '',
        user: {
          roleId: '',
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          dateOfBirth: '',
        },
        address: {
          userId: '',
          street: '',
          suburb: '',
          state: '',
          postCode: '',
          country: '',
        },
        business: {
          userId: '',
          addressId: '',
          name: '',
        },
        customer: {
          referredBy: '',
          addressId: '',
          userId: '',
          type: '',
          status: '',
          newsletterSubscription: '',
        },
        referrer: {
          referralCode: '',
        },
      }
    },

    async jumpToStart() {
      this.userSearchStatus = false;
      this.userFound = false;
      this.userEmailFilled = false;
      this.userPhoneFilled = false;
      this.tabStep = 1;
      this.userId = null;

      await this.resetUsers();
      await this.resetUser();
      await this.resetPostUserData();
      await this.resetSelectedRole();
      await this.resetSelectedAddress();
      await this.resetErrors();

      this.addressInformationSelectDiv = false;
      this.hasUserAddresses = false;

    },

    async validateEmailString(string) {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(string);
    },

    async searchUser() {
      this.loader(true);
      this.userSearchEmailDomainPartQuery = '';

      this.getUsersParams.email_phone_where_has_user_additional_infos_query = this.userSearchQuery;
      await this.getUsers(this.getUsersParams);

      if (this.users.length > 0) {

        let user = this.users[0];

        if (user && user.customer && user.customer.id) {
          await this.setExistingRegisteredCustomerError();
          await this.setExistingRegisteredCustomer(user, user.customer.id);
          this.loader(false);
          return;
        }

        await this.resetExistingRegisteredCustomerError()
        await this.resetExistingRegisteredCustomer();

        this.userFound = true;

        this.userId = user.id;

        await this.setPostUserData(user);
        await this.setExistingRegisteredUserData(user);

        this.postAddressData.user_id = this.userId;
        this.postBusinessData.user_id = this.userId;
        this.postCustomerData.user_id = this.userId;
      }

      if (this.users.length <= 0) {
        if (await this.validateEmailString(this.userSearchQuery)) {
          this.postUserData.email = this.userSearchQuery;
          this.userEmailFilled = true;
        }

        if (!await this.validateEmailString(this.userSearchQuery)) {
          this.postUserData.phone_number = this.userSearchQuery;
          this.userPhoneFilled = true;
        }
      }

      if (this.addressList.length > 1) {
        this.addressInformationSelectDiv = true;
        this.hasUserAddresses = true;
      }

      this.userSearchStatus = true;

      this.loader(false);
    },

    async createSingleUser() {
      if (!this.userId) {
        this.loader(true);
        await this.postUser(this.postUserData).then(async (response) => {
          if (response.status === 200 || response.status === 201) {
            let user = this.$store.getters['appUsers/user'];
            await this.setExistingRegisteredUserData(user);

            this.userId = user.id;
            this.postAddressData.user_id = this.userId;
            this.postBusinessData.user_id = this.userId;
            this.postCustomerData.user_id = this.userId;


            await this.tabNext();
          }

          this.errors.user.roleId = response.errors && response.errors.role_id ? response.errors.role_id[0] : '';
          this.errors.user.firstName = response.errors && response.errors.first_name ? response.errors.first_name[0] : '';
          this.errors.user.lastName = response.errors && response.errors.last_name ? response.errors.last_name[0] : '';
          this.errors.user.email = response.errors && response.errors.email ? response.errors.email[0] : '';
          this.errors.user.phoneNumber = response.errors && response.errors.phone_number ? response.errors.phone_number[0] : '';
          this.errors.user.dateOfBirth = response.errors && response.errors.date_of_birth ? response.errors.date_of_birth[0] : '';

          if (response.message) {
            this.showToastMessage(response);
          }
        });
        this.loader(false);
        return;
      }

      if (this.userId && this.existingRegisteredUserDataHasChanged) {
        this.loader(true);
        let dataObj = {
          id: this.userId,
          data: this.postUserData,
        };
        await this.putUser(dataObj).then(async (response) => {
          if (response.status === 200) {
            let user = this.$store.getters['appUsers/user'];
            await this.setExistingRegisteredUserData(user);

            this.postAddressData.user_id = this.userId;
            this.postBusinessData.user_id = this.userId;
            this.postCustomerData.user_id = this.userId;

            await this.tabNext();

          }

          this.errors.user.roleId = response.errors && response.errors.role_id ? response.errors.role_id[0] : '';
          this.errors.user.firstName = response.errors && response.errors.first_name ? response.errors.first_name[0] : '';
          this.errors.user.lastName = response.errors && response.errors.last_name ? response.errors.last_name[0] : '';
          this.errors.user.email = response.errors && response.errors.email ? response.errors.email[0] : '';
          this.errors.user.phoneNumber = response.errors && response.errors.phone_number ? response.errors.phone_number[0] : '';
          this.errors.user.dateOfBirth = response.errors && response.errors.date_of_birth ? response.errors.date_of_birth[0] : '';

          this.showToastMessage(response);
        });
        this.loader(false);
        return;
      }

      if (this.userId && !this.existingRegisteredUserDataHasChanged && this.postCustomerData.type !== '') {
        await this.tabNext();
      }
    },

    googleMapApiResponseData: function (googleMapApiResponseData) {
      this.loader(true);
      let subpremise = googleMapApiResponseData.subpremise ?? '';
      let streetNumber = googleMapApiResponseData.street_number ?? '';
      let streetName = googleMapApiResponseData.route ?? '';
      let suburbs = googleMapApiResponseData.locality ?? '';
      let state = googleMapApiResponseData.administrative_area_level_1 ?? '';
      let postCode = googleMapApiResponseData.postal_code ?? '';
      let country = googleMapApiResponseData.country ?? '';

      this.postAddressData.subpremise = this.titleCase(subpremise);
      this.postAddressData.street = streetNumber ? `${streetNumber} ${streetName}` : streetName;
      this.postAddressData.suburb = suburbs;
      this.postAddressData.state = state.toLowerCase();
      this.postAddressData.post_code = postCode;
      this.postAddressData.country = country;

      if (state) {
        let addressStateIndex = this.stateList.findIndex(item => item.value === state.toLowerCase());
        if (addressStateIndex !== -1) {
          let addressState = this.stateList[addressStateIndex];

          this.selectedState.value = addressState.value;
          this.selectedState.name = addressState.name;
        }
      }
      this.loader(false);
    },

    async createSingleAddress() {
      this.loader(true);
      await this.postAddress(this.postAddressData).then(async (response) => {
        if (response.status === 200 || response.status === 201) {

          let address = this.$store.getters['appAddresses/address'];
          this.postBusinessData.address_id = address.id;
          this.postCustomerData.address_id = address.id;

          if (this.postCustomerData.type === 2) {
            await this.createSingleBusinessAddress();
            if (this.errors.business.name || this.errors.business.addressId) {
              this.loader(false);
              return;
            }
          }

          let business = this.$store.getters['appBusinesses/business'];

          let newAddress = this.postCustomerData.type === 2 ? {
            value: address.id,
            name: `${business.name} (${address.street}, ${address.suburb} ${address.state} ${address.post_code}, ${address.country})`,
          } : {
            value: address.id,
            name: `${address.street}, ${address.suburb} ${address.state} ${address.post_code}, ${address.country}`
          };

          this.addressList.push(newAddress);
          this.hasUserAddresses = true;

          await this.tabNext();
        }

        this.errors.address.userId = response.errors && response.errors.user_id ? response.errors.user_id[0] : '';
        this.errors.address.street = response.errors && response.errors.street ? response.errors.street[0] : '';
        this.errors.address.suburb = response.errors && response.errors.suburb ? response.errors.suburb[0] : '';
        this.errors.address.state = response.errors && response.errors.state ? response.errors.state[0] : '';
        this.errors.address.postCode = response.errors && response.errors.post_code ? response.errors.post_code[0] : '';
        this.errors.address.country = response.errors && response.errors.country ? response.errors.country[0] : '';

        if (response.message) {
          this.showToastMessage(response);
        }

        if (this.errors.address.userId) {
          await this.tabJump(1);
        }

      });
      this.loader(false);
    },

    async createSingleBusinessAddress() {
      this.loader(true);
      await this.postBusiness(this.postBusinessData).then(async (response) => {

        this.errors.business.name = response.errors && response.errors.name ? response.errors.name[0] : '';
        this.errors.business.addressId = response.errors && response.errors.address_id ? response.errors.address_id[0] : '';
        this.errors.business.userId = response.errors && response.errors.user_id ? response.errors.user_id[0] : '';

        if (response.message) {
          this.showToastMessage(response);
        }

        if (this.errors.business.userId) {
          await this.tabJump(1);
        }
      });
      this.loader(false);
    },

    async getReferrerCustomer() {
      let paramObj = {
        referrerCode: this.referralCode,
        params: {
                  with_relation:['user']
        }
      };

      await this.getReferrer(paramObj).then((response) => {
        if (response.status === 200) {
          this.postCustomerData.referred_by = this.$store.getters['appCustomers/referrerCustomer'].user.id;

        }

        this.errors.referrer.referralCode = response.status === 404 ? 'Invalid code.' : '';

        if (response.message) {
          const toastObj = {
            message: 'No referrer found with the code.',
            type: response.type
          };

          this.showToastMessage(toastObj);
        }
      });
    },

     
      loyaltyCustomerRegistration(customerId){
            this.postLoyaltyCustomerRegistration({customer_id:customerId});
      },

      loyaltyCustomerReferrer(customerId){
            this.postLoyaltyCustomerRefferar({customer_id: customerId});
      },

    async createSingleCustomer() {
      this.loader(true);
      if (this.referralCode) {
        await this.getReferrerCustomer();
      }

      if ((this.referralCode && this.errors.referrer.referralCode)) {
        this.loader(false);
        await this.tabJump(3);
        return;
      }

      await this.postCustomer(this.postCustomerData).then(async (response) => {
        if (response.status === 201) {
          this.loyaltyCustomerRegistration(this.$store.getters['appCustomers/customer'].id);
          if (this.referrerCustomer?.user?.id && this.referralCode) {
            this.loyaltyCustomerReferrer(this.$store.getters['appCustomers/customer'].id);
          }
          const toastObj = {
            message: 'Customer created successful.',
            type: 'success'
          };

          this.showToastMessage(toastObj);

          // For order and appointment create route redirect method
          if (this.$props.routeName) {
            await this.$router.push({
              name: this.$props.routeName,
              params: {
                customerId: this.$store.getters['appCustomers/customer'].id,
              },
            });
          }

          if (!(this.$props.routeName)) {
            await this.$router.push({
              name: this.redirectRouteName,
            });
          }

        }

        this.errors.customer.userId = response.errors && response.errors.user_id ? response.errors.user_id[0] : '';
        this.errors.customer.addressId = response.errors && response.errors.address_id ? response.errors.address_id[0] : '';
        this.errors.customer.referredBy = response.errors && response.errors.referred_by ? response.errors.referred_by[0] : '';
        this.errors.customer.type = response.errors && response.errors.type ? response.errors.type[0] : '';
        this.errors.customer.status = response.errors && response.errors.status ? response.errors.status[0] : '';
        this.errors.customer.newsletterSubscription = response.errors && response.errors.newsletter_subscription ? response.errors.newsletter_subscription[0] : '';

        if (response.message) {
          this.showToastMessage(response);
        }

        if (this.errors.customer.userId || this.errors.customer.type) {
          await this.tabJump(1);
          this.loader(false);
          return;
        }

        if (this.errors.customer.addressId) {
          await this.tabJump(2);
          this.loader(false);
          return;
        }

        if (this.errors.customer.referredBy || this.errors.customer.newsletterSubscription) {
          await this.tabJump(3);
          this.loader(false);
        }
      });

      this.loader(false);
    },

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    async getRegisterEmailDomainList() {
      await this.getRegisteredEmailDomains();
    },
  },

  async mounted() {
    await this.getSettingList();
    this.postUserData.role_id = await this.$store.getters["appSettings/settingDefaultCustomerRole"].value?.value;
    await this.getRegisterEmailDomainList();
  },

  async beforeMount() {
    await this.resetUser();
    await this.resetUsers();
    await this.resetAddress();
    await this.resetBusiness();
    await this.resetCustomer();
  }
}
</script>

<style scoped>
.center-div {
  width: 85%;
}

.step-number {
  width: 38px;
  height: 38px;
  color: white;
  border-radius: 0.375rem
}

.step-line {
  width: 38px;
  height: 38px;

}

.inner-line {
  display: inline-block !important;
  /* border-left: 1px solid #5a8dee; */
  height: 100%;
}

.inner-line-primary-border {
  border-left: 1px solid #5a8dee;
}

.inner-line-secondary-border {
  border-left: 1px solid #808080FF;
}

.active-step {
  background-color: #5a8dee;
}

.inactive-step {
  background-color: #90a5cc;
}

.step-divider {
  border-right: 1px solid #d4d8dd;
}

.email-domain-list {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  margin: 0 1rem;
  background-color: #808080FF;
  max-height: 217px;
  background-color: white;
  overflow: hidden;
  overflow-y: auto;
  padding: 0.5rem 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 1px solid #d2ddec;
  border-right: 1px solid #d2ddec;
  border-bottom: 1px solid #d2ddec;
  z-index: 30;
  -webkit-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.25);
}

.email-domain-list-item {
  padding-left: 1rem;
  cursor: pointer;
  margin: 2px 0;
  color: black;
}

.email-domain-list-item:hover {
  background-color: #5a8dee;
  color: white;

}

@media only screen and (max-width: 600px) {
  .center-div {
    width: 100% !important;
  }
}

@media only screen and (max-width: 991px) {
  .step-divider {
    border-right: none;
    border-bottom: 1px solid #d4d8dd;
    padding-bottom: 27px;
  }
}
</style>